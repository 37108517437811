export const ProductsMap = {
    OPEN_PRODUCTS_ADD_IMAGES_MODAL: 'OPEN_PRODUCTS_ADD_IMAGES_MODAL',
    CLOSE_PRODUCTS_ADD_IMAGES_MODAL: 'CLOSE_PRODUCTS_ADD_IMAGES_MODAL',
    SET_PRODUCT_PAGE: 'SET_PRODUCT_PAGE',
    SET_PRODUCT_PAGE_SIZE: 'SET_PRODUCT_PAGE_SIZE',
    GET_ALL_PRODUCT_LIST: 'GET_ALL_PRODUCT_LIST',
    GET_ALL_PRODUCT_LIST_SUCCESS: 'GET_ALL_PRODUCT_LIST_SUCCESS',
    GET_ALL_PRODUCT_LIST_ERROR: 'GET_ALL_PRODUCT_LIST_ERROR',
    ADD_BLANK_IMAGE: 'ADD_BLANK_IMAGE',
    ADD_BLANK_IMAGE_SUCCESS: 'ADD_BLANK_IMAGE_SUCCESS',
    ADD_BLANK_IMAGE_ERROR: 'ADD_BLANK_IMAGE_ERROR',
    UPDATE_BLANK_IMAGE: 'UPDATE_BLANK_IMAGE',
    UPDATE_BLANK_IMAGE_SUCCESS: 'UPDATE_BLANK_IMAGE_SUCCESS',
    UPDATE_BLANK_IMAGE_ERROR: 'UPDATE_BLANK_IMAGE_ERROR',
    GET_PRODUCT_BY_ID: 'GET_PRODUCT_BY_ID',
    GET_PRODUCT_BY_ID_SUCCESS: 'GET_PRODUCT_BY_ID_SUCCESS',
    GET_PRODUCT_BY_ID_ERROR: 'GET_PRODUCT_BY_ID_ERROR',
    EMPTY_BLANK_IMAGE: 'EMPTY_BLANK_IMAGE',
    DELETE_PRODUCT_IMAGE: 'DELETE_PRODUCT_IMAGE',
    DELETE_PRODUCT_IMAGE_SUCCESS: 'DELETE_PRODUCT_IMAGE_SUCCESS',
    DELETE_PRODUCT_IMAGE_ERROR: 'DELETE_PRODUCT_IMAGE_ERROR',
    UPDATE_BOUNDARY_BOX: 'UPDATE_BOUNDARY_BOX',
    UPDATE_BOUNDARY_BOX_SUCCESS: 'UPDATE_BOUNDARY_BOX_SUCCESS',
    UPDATE_BOUNDARY_BOX_ERROR: 'UPDATE_BOUNDARY_BOX_ERROR',
    PRODUCT_DETAILS_START: 'PRODUCT_DETAILS_START',
    PRODUCT_DETAILS_SUCCESS: 'PRODUCT_DETAILS_SUCCESS',
    PRODUCT_DETAILS_ERROR: 'PRODUCT_DETAILS_ERROR',
    DRAW_BOUNDARY_BOX: 'DRAW_BOUNDARY_BOX',
    RESET_BOUNDARY_BOX: 'RESET_BOUNDARY_BOX',
    SYNC_PRODUCTS: 'SYNC_PRODUCTS',
    SYNC_PRODUCTS_SUCCESS: 'SYNC_PRODUCTS_SUCCESS',
    SYNC_PRODUCTS_ERROR: 'SYNC_PRODUCTS_ERROR',
    SHOW_SYNC_MODAL: 'SHOW_SYNC_MODAL',
    SET_SELECTED_PRODUCT: 'SET_SELECTED_PRODUCT',
    UPDATE_PRODUCT_STATUS_START: 'UPDATE_PRODUCT_STATUS_START',
    UPDATE_PRODUCT_STATUS_SUCCESS: 'UPDATE_PRODUCT_STATUS_SUCCESS',
    UPDATE_PRODUCT_STATUS_ERROR: 'UPDATE_PRODUCT_STATUS_ERROR'
}

export const ProductsActions = {
    openAddImages: () => ({ type: ProductsMap.OPEN_PRODUCTS_ADD_IMAGES_MODAL }),
    closeAddImages: () => ({ type: ProductsMap.CLOSE_PRODUCTS_ADD_IMAGES_MODAL }),

    setPage: (num) => ({ type: ProductsMap.SET_PRODUCT_PAGE, payload: num }),
    setPageSize: (num) => ({ type: ProductsMap.SET_PRODUCT_PAGE_SIZE, payload: num }),

    getAllProductList: (data) => ({ type: ProductsMap.GET_ALL_PRODUCT_LIST, payload: data }),
    getAllProductListSuccess: (data) => ({ type: ProductsMap.GET_ALL_PRODUCT_LIST_SUCCESS, payload: data }),
    getAllProductListError: () => ({ type: ProductsMap.GET_ALL_PRODUCT_LIST_ERROR }),

    addBlankImage: (data) => ({ type: ProductsMap.ADD_BLANK_IMAGE, payload: data }),
    addBlankImageSuccess: () => ({ type: ProductsMap.ADD_BLANK_IMAGE_SUCCESS }),
    addBlankImageError: () => ({ type: ProductsMap.ADD_BLANK_IMAGE_ERROR }),
    emptyBlankImage: () => ({ type: ProductsMap.EMPTY_BLANK_IMAGE }),

    updateBlankImage: (data) => ({ type: ProductsMap.UPDATE_BLANK_IMAGE, payload: data }),
    updateBlankImageSuccess: () => ({ type: ProductsMap.UPDATE_BLANK_IMAGE_SUCCESS }),
    updateBlankImageError: () => ({ type: ProductsMap.UPDATE_BLANK_IMAGE_ERROR }),

    getProductById: (data) => ({ type: ProductsMap.GET_PRODUCT_BY_ID, payload: data }),
    getProductByIdSuccess: (data) => ({ type: ProductsMap.GET_PRODUCT_BY_ID_SUCCESS, payload: data }),
    getProductByIdError: () => ({ type: ProductsMap.GET_PRODUCT_BY_ID_ERROR }),

    deleteProductImage: (data) => ({ type: ProductsMap.DELETE_PRODUCT_IMAGE, payload: data }),
    deleteProductImageSuccess: () => ({ type: ProductsMap.DELETE_PRODUCT_IMAGE_SUCCESS }),
    deleteProductImageError: (data) => ({ type: ProductsMap.DELETE_PRODUCT_IMAGE_ERROR, payload: data }),

    updateBoundaryBox: (data, cb) => ({ type: ProductsMap.UPDATE_BOUNDARY_BOX, payload: { data, cb } }),
    updateBoundaryBoxSuccess: () => ({ type: ProductsMap.UPDATE_BOUNDARY_BOX_SUCCESS }),
    updateBoundaryBoxError: (data) => ({ type: ProductsMap.UPDATE_BOUNDARY_BOX_ERROR, payload: data }),

    syncProducts: (data) => ({ type: ProductsMap.SYNC_PRODUCTS, payload: data }),
    syncProductSuccess: () => ({ type: ProductsMap.SYNC_PRODUCTS_SUCCESS }),
    syncProductError: () => ({ type: ProductsMap.SYNC_PRODUCTS_ERROR }),

    setShowSyncModal: (data) => ({ type: ProductsMap.SHOW_SYNC_MODAL, payload: data }),

    setSelectedProduct: (data) => ({ type: ProductsMap.SET_SELECTED_PRODUCT, payload: data }),
    productDetails: (data) => ({ type: ProductsMap.PRODUCT_DETAILS_START, payload: data }),
    productDetailsSuccess: () => ({ type: ProductsMap.PRODUCT_DETAILS_SUCCESS }),
    productDetailsError: () => ({ type: ProductsMap.PRODUCT_DETAILS_ERROR }),

    updateProductStatusStart: (data) => ({ type: ProductsMap.UPDATE_PRODUCT_STATUS_START, payload: data }),
    updateProductStatusSuccess: () => ({ type: ProductsMap.UPDATE_PRODUCT_STATUS_SUCCESS }),
    updateProductStatusError: () => ({ type: ProductsMap.UPDATE_PRODUCT_STATUS_ERROR }),
}