import { ProductsMap } from './products.action';

const initState = {
    modalAddImages: false,
    productsList: [],
    isLoading: false,
    totalRecords: 0,
    pageNumber: 1,
    pageSize: 10,
    refreshProducts: true,
    blankImageList: {
        image: [],
    },
    refreshBlankImage: true,
    showSyncModal: false,
    selectedProduct: null,
}

export const reducer = (state = initState, action) => {
    switch (action.type) {

        case ProductsMap.SYNC_PRODUCTS:
        case ProductsMap.ADD_BLANK_IMAGE:
        case ProductsMap.GET_PRODUCT_BY_ID:
        case ProductsMap.UPDATE_BLANK_IMAGE:
        case ProductsMap.UPDATE_BOUNDARY_BOX:
        case ProductsMap.DELETE_PRODUCT_IMAGE:
        case ProductsMap.GET_ALL_PRODUCT_LIST: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case ProductsMap.GET_ALL_PRODUCT_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                productsList: action.payload.list,
                totalRecords: action.payload.count,
                refreshProducts: false,
            }
        }

        case ProductsMap.GET_PRODUCT_BY_ID_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                blankImageList: action.payload,
                refreshBlankImage: false,
            }
        }

        case ProductsMap.GET_PRODUCT_BY_ID_ERROR:
        case ProductsMap.GET_ALL_PRODUCT_LIST_ERROR: {
            return {
                ...state,
                isLoading: false,
                refreshBlankImage: false,
            }
        }

        case ProductsMap.UPDATE_BOUNDARY_BOX_SUCCESS:
        case ProductsMap.UPDATE_BLANK_IMAGE_SUCCESS:
        case ProductsMap.DELETE_PRODUCT_IMAGE_SUCCESS:
        case ProductsMap.ADD_BLANK_IMAGE_SUCCESS: {
            return {
                ...state,
                modalAddImages: false,
                isLoading: false,
                refreshBlankImage: true,
            }
        }

        case ProductsMap.UPDATE_BOUNDARY_BOX_ERROR:
        case ProductsMap.DELETE_PRODUCT_IMAGE_ERROR:
        case ProductsMap.UPDATE_BLANK_IMAGE_ERROR:
        case ProductsMap.ADD_BLANK_IMAGE_ERROR: {
            return {
                ...state,
                modalAddImages: false,
                isLoading: false,
                refreshBlankImage: false,
            }
        }

        case ProductsMap.EMPTY_BLANK_IMAGE: {
            return {
                ...state,
                blankImageList: {},
                refreshBlankImage: true,
            }
        }

        case ProductsMap.SYNC_PRODUCTS_SUCCESS: {
            return {
                ...state,
                refreshProducts: true,
                isLoading: false,
                showSyncModal: false,
            }
        }

        case ProductsMap.SYNC_PRODUCTS_ERROR: {
            return {
                ...state,
                isLoading: false,
                showSyncModal: false,
            }
        }

        case ProductsMap.SHOW_SYNC_MODAL: {
            return {
                ...state,
                showSyncModal: action.payload,
            }
        }

        case ProductsMap.UPDATE_PRODUCT_STATUS_START:
        case ProductsMap.PRODUCT_DETAILS_START: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case ProductsMap.PRODUCT_DETAILS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                // refreshProducts: true,
            }
        }
        case ProductsMap.UPDATE_PRODUCT_STATUS_ERROR:
        case ProductsMap.PRODUCT_DETAILS_ERROR: {
            return {
                ...state,
                isLoading: false,
            }
        }

        case ProductsMap.OPEN_PRODUCTS_ADD_IMAGES_MODAL: {
            return {
                ...state,
                modalAddImages: true
            }
        }
        case ProductsMap.CLOSE_PRODUCTS_ADD_IMAGES_MODAL: {
            return {
                ...state,
                modalAddImages: false
            }
        }
        case ProductsMap.SET_SELECTED_PRODUCT: {
            return {
                ...state,
                selectedProduct: action.payload || null,
            }
        }
        case ProductsMap.SET_PRODUCT_PAGE: {
            return {
                ...state,
                pageNumber: action.payload,
                refreshProducts: true
            }
        }
        case ProductsMap.SET_PRODUCT_PAGE_SIZE: {
            return {
                ...state,
                pageSize: action.payload,
                refreshProducts: true
            }
        }

        case ProductsMap.UPDATE_PRODUCT_STATUS_SUCCESS: {
            return {
                ...state,
                refreshProducts: true,
                isLoading: false,
                pageNumber: 1
            }
        }

        default: {
            return {
                ...state
            }
        }
    }
}