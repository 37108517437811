import axios from 'axios';
// import {serverConfig} from '../../../utils/constants';

const getErrorMsg = (data) => {
    if (data.response) {
        return data.response.responseMessage || data.response.message;
    }
    if (data.error) {
        return data.error.message || data.error.errors[0].responseMessage;
    }
    return "Please try again after sometime.";
}

export const syncProducts = async (skus) => {
    try {
        let url = `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_BASE_PATH}/products/syncProducts`;
        let { data } = await axios({
            method: "POST",
            url,
            data: {
                sku: skus
            },
            headers: {
                'Content-Type': "application/json"
            }
        });
        console.log(data);
        if (data.response && data.response.responseCode === 200) {
           return data.response;
        }

        throw new Error(getErrorMsg(data));
    } catch (error) {
        console.log(error)
        throw new Error(error.message)
    }
}

export const getAllProductList = async ({ pageNumber, pageSize, payload }) => {
    try {
        // const defaultSku = "80-28510,80-43020,80-44565,80-44568,80-44575,80-46020,80-49860,80-59075,80-60020,80-60079,80-60073,80-60074,80-60095,80-60076 ,80-46520,80-67520,80-68011,80-68117,80-68417,80-68517,80-68520,80-68622,80-69000,80-69012,80-69016,80-69112,80-69517,80-70117,80-71117,80-73611,80-77016";
        let url = `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_BASE_PATH}/products/getSyncProducts`;
        let start = pageNumber - 1;
        start = start ? (start * pageSize) + 1 : 0;
        if(payload && payload.sku_id) {
            url += `?sku_id=${payload.sku_id}`;
        } else {
            url += `?start=${start}&limit=${pageSize}`;
        }
        let { data } = await axios({
            method: "GET",
            url,
        });
        console.log(data);
        if (data.response && data.response.responseCode === 200) {
            return {
                count: data.response.data.total,
                list: data.response.data.list,
            };
        }

        throw new Error(getErrorMsg(data));
    } catch (error) {
        console.log(error)
        throw new Error(error.message)
    }
}

export const addBlankImage = async (imageDetails) => {
    try {
        let { data } = await axios({
            method: "POST",
            url: `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_BASE_PATH}/products/addProductImage`,
            data: imageDetails,
            headers: {
                'Content-Type': 'form/multipart',
            }
        });

        if (data.response && data.response.responseCode === 200) {
            return data.response.responseMessage;
        }

        throw new Error(getErrorMsg(data));
    } catch (error) {
        console.log(error)
        throw new Error(error.message)
    }
}

export const updateBlankImage = async (imageDetails) => {
    try {
        let { data } = await axios({
            method: "POST",
            url: `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_BASE_PATH}/products/updateProductImage`,
            data: imageDetails,
            headers: {
                'Content-Type': 'form/multipart',
            }
        });

        if (data.response && data.response.responseCode === 200) {
            return data.response.responseMessage;
        }

        throw new Error(getErrorMsg(data));
    } catch (error) {
        console.log(error)
        throw new Error(error.message)
    }
}

export const getProductImagesById = async ({ sku, productId }) => {
    try {
        let { data } = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_BASE_PATH}/products/getProductsById/${sku}/${productId}`,
        });

        if (data.response && data.response.responseCode === 200) {
            return data.response.data;
        }

        throw new Error(getErrorMsg(data));
    } catch (error) {
        console.log(error)
        throw new Error(error.message)
    }
}

export const deleteProductImage = async ({ sku, productId, _id }) => {
    try {
        let { data } = await axios({
            method: "POST",
            url: `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_BASE_PATH}/products/deleteProductImage/${sku}/${productId}/${_id}`,
        });

        if (data.response && data.response.responseCode === 200) {
            return data.response.responseMessage;
        }

        throw new Error(getErrorMsg(data));
    } catch (error) {
        console.log(error)
        throw new Error(error.message)
    }
}

export const updateBoundaryBox = async (productDetails) => {
    console.log(productDetails)
    try {
        let { data } = await axios({
            method: "POST",
            url: `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_BASE_PATH}/products/saveBoundaryBox`,
            data: productDetails,
            headers: {
                'Content-Type': 'application/json',
            }
        });

        if (data.response && data.response.responseCode === 200) {
            return data.response.responseMessage;
        }

        throw new Error(getErrorMsg(data));
    } catch (error) {
        console.log(error)
        throw new Error(error.message)
    }
}

export const productDetailsApi = async (productDetails) => {
    try {
        let { data } = await axios({
            method: "POST",
            url: `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_BASE_PATH}/products/updateProductDetails`,
            data: productDetails,
            headers: {
                'Content-Type': 'form/multipart',
            }
        });
        if (data.response && data.response.responseCode === 200) {
            return data.response.responseMessage;
        }
        throw new Error(getErrorMsg(data));
    } catch (error) {
        console.log(error)
        throw new Error(error.message)
    }
}

export const updateProductStatusApi = async (productDetails) => {
    try {
        let { data } = await axios({
            method: "POST",
            url: `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_BASE_PATH}/products/updateProductStatus`,
            data: productDetails,
        });
        if (data.response && data.response.responseCode === 200) {
            return data.response.responseMessage;
        }
        throw new Error(getErrorMsg(data));
    } catch (error) {
        console.log(error)
        throw new Error(error.message)
    }
}