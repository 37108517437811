import { put, takeLatest, call, all, fork, select } from "redux-saga/effects";
import { ProductsMap, ProductsActions } from './products.action';
import {
    getAllProductList, addBlankImage, getProductImagesById, updateBlankImage, deleteProductImage, 
    updateBoundaryBox, syncProducts, productDetailsApi, updateProductStatusApi
} from './products.api';

function* watchProductListSaga({ payload }) {
    try {
        const products = yield select(state => state.products);
        const data = yield call(getAllProductList, { pageNumber: products.pageNumber, pageSize: products.pageSize, payload });
        yield put(ProductsActions.getAllProductListSuccess(data));
    } catch (error) {
        yield put(ProductsActions.getAllProductListError(error.message));
    }
}

function* watchGetProductImageById({ payload }) {
    try {
        const data = yield call(getProductImagesById, payload);;
        yield put(ProductsActions.getProductByIdSuccess(data));
    } catch (error) {
        yield put(ProductsActions.getProductByIdError(error.message));
    }
}

function* watchAddBlankImageSaga({ payload }) {
    try {
        const data = yield call(addBlankImage, payload);
        yield put(ProductsActions.addBlankImageSuccess());
    } catch (error) {
        yield put(ProductsActions.addBlankImageError(error.message));
    }
}

function* watchUpdateBlankImageSaga({ payload }) {
    try {
        const data = yield call(updateBlankImage, payload);
        yield put(ProductsActions.updateBlankImageSuccess(data));
    } catch (error) {
        yield put(ProductsActions.updateBlankImageError(error.message));
    }
}

function* watchDeleteBlankImageSaga({ payload }) {
    try {
        const data = yield call(deleteProductImage, payload);
        yield put(ProductsActions.deleteProductImageSuccess(data));
    } catch (error) {
        yield put(ProductsActions.deleteProductImageError(error.message));
    }
}

function* watchUpdateBoundaryBox({ payload }) {
    try {
        const data = yield call(updateBoundaryBox, payload.data);
        yield put(ProductsActions.updateBoundaryBoxSuccess());
        if (typeof payload.cb === "function") {
            yield put(payload.cb);
        }
        yield put({ type: "ADD_SNACKBAR", payload: { message: "Boundary box add success", variant: 'success' } });
    } catch (error) {
        yield put(ProductsActions.updateBoundaryBoxError(error.message));
        yield put({ type: "ADD_SNACKBAR", payload: { message: "Boundary box add error", variant: 'error' } });
    }
}

function* watchSyncProducts({ payload }) {
    try {
        const data = yield call(syncProducts, payload);
        yield put(ProductsActions.syncProductSuccess(data));
        yield put({ type: "ADD_SNACKBAR", payload: { message: "Product Sync Successfull", variant: 'success' } });
    } catch (error) {
        console.log(error)
        yield put(ProductsActions.syncProductError(error.message));
        yield put({ type: "ADD_SNACKBAR", payload: { message: "Product Sync Error", variant: 'error' } });
    }
}

function* watchUpdateProductStatus({ payload }) {
    try {
        const data = yield call(updateProductStatusApi, payload);
        yield put(ProductsActions.updateProductStatusSuccess(data));
        yield put({ type: "ADD_SNACKBAR", payload: { message: "Product status update successfull", variant: 'success' } });
    } catch (error) {
        console.log(error)
        yield put(ProductsActions.updateProductStatusError(error.message));
        yield put({ type: "ADD_SNACKBAR", payload: { message: "Product status update Error", variant: 'error' } });
    }
}


function* watchProductsDetails({ payload }) {
    try {
        const data = yield call(productDetailsApi, payload);
        yield put(ProductsActions.productDetailsSuccess(data));
        yield put({ type: "ADD_SNACKBAR", payload: { message: "Product Details saved Successfull", variant: 'success' } });
    } catch (error) {
        console.log(error)
        yield put(ProductsActions.productDetailsError(error.message));
        yield put({ type: "ADD_SNACKBAR", payload: { message: error.message, variant: 'error' } });
    }
}

function* syncProductsSaga() {
    yield takeLatest(ProductsMap.SYNC_PRODUCTS, watchSyncProducts);
}

function* allProductListSaga() {
    yield takeLatest(ProductsMap.GET_ALL_PRODUCT_LIST, watchProductListSaga);
}

function* addBlankImageSaga() {
    yield takeLatest(ProductsMap.ADD_BLANK_IMAGE, watchAddBlankImageSaga);
}

function* updateBlankImageSaga() {
    yield takeLatest(ProductsMap.UPDATE_BLANK_IMAGE, watchUpdateBlankImageSaga);
}

function* deleteBlankImageSaga() {
    yield takeLatest(ProductsMap.DELETE_PRODUCT_IMAGE, watchDeleteBlankImageSaga);
}

function* getProductImageByIdSaga() {
    yield takeLatest(ProductsMap.GET_PRODUCT_BY_ID, watchGetProductImageById);
}

function* updateBoundaryBoxSaga() {
    yield takeLatest(ProductsMap.UPDATE_BOUNDARY_BOX, watchUpdateBoundaryBox);
}

function* watchProductsStatusUpdateSaga() {
    yield takeLatest(ProductsMap.UPDATE_PRODUCT_STATUS_START, watchUpdateProductStatus);
}

function* watchProductsDetailsSaga() {
    yield takeLatest(ProductsMap.PRODUCT_DETAILS_START, watchProductsDetails);
}

export function* productsSaga() {
    yield all([
        fork(allProductListSaga),
        fork(addBlankImageSaga),
        fork(getProductImageByIdSaga),
        fork(updateBlankImageSaga),
        fork(deleteBlankImageSaga),
        fork(updateBoundaryBoxSaga),
        fork(syncProductsSaga),
        fork(watchProductsDetailsSaga),
        fork(watchProductsStatusUpdateSaga)
    ]);
}